<template>
    <div>
        <!-- 登录 -->
        <el-dialog :modelValue="dialogVisible" width="30%"
            style="top: 5%;width: 902px;background: #F8FBFF;border-radius: 8px;" :before-close="handleClose">
            <div class="LoginBox-popup">
                <div class="left-content">
                    <div class="scan-code-prompt log-into-the-site"><span>微信</span>扫描二维码</div>
                    <div class="log-onto-code">
                        <!-- <img src="@/assets/index/code.png" alt="" srcset=""> -->
                        <img src="@/assets/image/qr.jpg" alt="" srcset="">
                    </div>
                    <!-- <div class="log-into-the-site">请使用<span>微信</span></div>
                    <div class="download-app">扫码登录或扫码下载APP</div> -->
                </div>
                <div class="right-content">
                    <div class="head-record">
                        <div :class="item.isflag == isLoginBox ? 'record-active' : ''" v-for="item in tabSwitching"
                            :key="item.id" @click="recordSwitch(item.isflag)">{{ item.title }}</div>
                    </div>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" v-show="!firstLogin">
                        <div class="personal-information">
                            <el-form-item v-if="isLoginBox" class="account-number" prop="accountNumber">
                                <div class="mobile-phone"><el-icon color="#9C9C9C" size="24">
                                        <User />
                                    </el-icon>账号</div>
                                <div class="inputBox">|<el-input v-model="ruleForm.accountNumber" type="text"
                                        placeholder="请输入注册手机号"></el-input></div>
                            </el-form-item>
                            <el-form-item v-if="isLoginBox" class="account-number password-number" prop="password">
                                <div class="mobile-phone"><el-icon color="#9C9C9C" size="24">
                                        <Lock />
                                    </el-icon>密码</div>
                                <div class="inputBox">|<el-input v-model="ruleForm.password" :type="passwordTitle"
                                        placeholder="请输入密码"></el-input></div>
                                <div class="password-settings">
                                    <div @click="lookSetting" class="password-look">
                                        <el-icon color="#9C9C9C" size="24" v-if="passwordTitle == 'text'">
                                            <View />
                                        </el-icon>
                                        <el-icon color="#9C9C9C" size="24" v-else><svg t="1683856854405" class="icon"
                                                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                p-id="2064" width="200" height="200">
                                                <path
                                                    d="M93.866667 322.773333a8.533333 8.533333 0 0 1 6.613333 3.114667c5.589333 6.848 10.261333 12.373333 14.058667 16.64 97.664 109.056 239.552 177.706667 397.482666 177.706667 162.752 0 308.48-72.917333 406.314667-187.84 1.493333-1.792 3.242667-3.882667 5.184-6.272a8.533333 8.533333 0 0 1 15.146667 5.376v9.813333l0.021333 8.32v51.754667a8.533333 8.533333 0 0 1-2.517333 6.037333 599.893333 599.893333 0 0 1-99.584 81.002667l82.474666 98.261333a8.533333 8.533333 0 0 1-1.066666 12.010667l-35.946667 30.165333a8.533333 8.533333 0 0 1-12.010667-1.045333l-89.813333-107.050667a593.045333 593.045333 0 0 1-145.450667 50.837333l44.074667 121.024a8.533333 8.533333 0 0 1-5.098667 10.944l-44.096 16.042667a8.533333 8.533333 0 0 1-10.944-5.098667l-48.448-133.098666a604.586667 604.586667 0 0 1-130.88-1.557334L390.4 714.517333a8.533333 8.533333 0 0 1-10.944 5.12l-44.096-16.064a8.533333 8.533333 0 0 1-5.12-10.944l45.184-124.096a593.066667 593.066667 0 0 1-131.584-47.744l-89.813333 107.029334a8.533333 8.533333 0 0 1-12.032 1.066666L106.026667 598.677333a8.533333 8.533333 0 0 1-1.066667-12.010666l82.474667-98.261334a599.872 599.872 0 0 1-80.981334-62.976c-4.352-4.032-10.56-10.026667-18.602666-18.005333A8.533333 8.533333 0 0 1 85.333333 401.386667v-70.101334c0-4.693333 3.84-8.533333 8.533334-8.533333z"
                                                    fill="#333333" p-id="2065"></path>
                                            </svg></el-icon>
                                    </div>
                                    <!-- 忘记密码 -->
                                    <div class="forgot-password" @click="toPassWordEdit">忘记密码?</div>
                                </div>
                            </el-form-item>
                            <el-form-item v-if="!isLoginBox" class="account-number" prop="cellPhoneNumber">
                                <div class="mobile-phone"><el-icon color="#9C9C9C" size="24">
                                        <Iphone />
                                    </el-icon>+86</div>
                                <div class="inputBox cell-phone">|<el-input v-model.number="ruleForm.cellPhoneNumber"
                                        type="text" oninput="value=value.replace(/[^\d]/g,'')" maxlength="11" placeholder="请输入手机号"></el-input></div>
                                <div class="get-verification-code" v-if="isTime" @click="getVerificationCode">获取验证码</div>
                                <el-button class="get-verification-code" type="text" disabled v-if="!isTime">{{ AMinute
                                }}s</el-button>
                            </el-form-item>
                            <el-form-item v-if="!isLoginBox" class="account-number password-number" prop="phoneCode">
                                <div class="mobile-phone"><el-icon color="#9C9C9C" size="24"><svg t="1683879208516"
                                            class="icon" viewBox="0 0 1024 1024" version="1.1"
                                            xmlns="http://www.w3.org/2000/svg" p-id="2166" width="200" height="200">
                                            <path
                                                d="M943.1 172c-2.4-0.2-245.1-25.3-413.8-147.8-5.1-3.7-11-5.6-17.3-5.6-6.2 0-12.2 1.9-17.3 5.6C326.9 146 83.3 171.8 80.9 172c-15.2 1.4-26.6 14.1-26.6 29.3 0 6.7 0.6 165.8 54.8 344.4 32.1 105.8 76.4 196.4 131.9 269.2 70.3 92.3 158.5 156 262 189.2 2.9 0.9 5.9 1.4 9 1.4s6.1-0.5 8.9-1.4c103.6-33.2 191.7-96.8 262-189.2 55.4-72.7 99.8-163.2 131.9-269.2 54.1-178.6 54.8-337.7 54.8-344.4C969.7 186.1 958.3 173.5 943.1 172zM910.1 227.2l-0.1 1.6c-2.9 58.1-13.4 174.4-51.4 299.9-66.7 220.1-183.1 360.1-346 416.1L512 945l-0.6-0.2C349 888.9 232.7 749.4 165.8 530.1c-39.8-130.5-49.4-254.2-51.8-301.4l-0.1-1.6 1.5-0.2c70.6-10.3 250.5-44.8 395.5-142.4l0.9-0.7 1 0.7C658 182.1 837.9 216.6 908.5 227L910.1 227.2z"
                                                p-id="2167"></path>
                                            <path
                                                d="M641.8 351 467 580.3l-89-76.1c-5.3-4.5-12.1-7-19.1-7-8.6 0-16.8 3.7-22.4 10.3-10.5 12.3-9.1 31 3.3 41.5l112.7 96.4c5.2 4.4 12.4 7 19.6 7 0.9 0 1.8 0 2.7-0.1 8-0.8 15.4-5 20.3-11.4l193.7-254c4.8-6.3 6.8-14 5.7-21.8-1-7.8-5.1-14.7-11.3-19.5C670.1 335.6 651.6 338.1 641.8 351z"
                                                p-id="2168"></path>
                                        </svg></el-icon>验证码</div>
                                <div class="inputBox">|<el-input v-model="ruleForm.phoneCode" type="text"
                                        placeholder="请输入验证码" oninput="value=value.replace(/[^\d]/g,'')"></el-input></div>
                            </el-form-item>
                            <div class="account-number">
                                <div id="sc"></div>
                            </div>
                        </div>
                        <!-- 第一次登录设置新的密码 -->

                        <!-- 第一次登陆没有密码 -->
                        <div class="button-box" style="justify-content: center;">
                            <div class="record-btn" @click="onSubmit1('ruleForm')" v-show="!firstLogin">{{isLoginBox?'':'注册/'}}登录</div>

                        </div>
                    </el-form>
                    <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" v-show="firstLogin">
                        <div class="personal-information-first" >
                            <el-form-item class="account-number password-number" prop="password">
                                <div class="mobile-phone-first"><el-icon color="#9C9C9C" size="24">
                                        <Lock />
                                    </el-icon></div>
                                <div class="inputBox">|<el-input v-model="ruleForm2.password" type="password"
                                        placeholder="请输入密码(最少六位数)"></el-input></div>
                                <div class="password-settings">

                                </div>
                            </el-form-item>
                            <el-form-item class="account-number password-number" prop="password">
                                <div class="mobile-phone-first"><el-icon color="#9C9C9C" size="24">
                                        <Lock />
                                    </el-icon></div>
                                <div class="inputBox">|<el-input v-model="ruleForm2.check_password" type="password"
                                        placeholder="请确认密码"></el-input></div>
                                <div class="password-settings">
                                </div>
                            </el-form-item>

                        </div>
                        <div class="button-box" style="justify-content: center;">
                            <div class="record-btn" @click="onSubmit2('ruleForm2')" v-show="firstLogin">确认</div>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>


<script >
import { ElMessage } from 'element-plus'
import { getLoginBoxSms, phoneLoginBox, onSubmit, repairPassword } from "@/api/login";
export default {
    name: 'DsEpidemicViewLoginBox',
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        LoginBoxClose: {
            type: Function,
            default: null
        }
    },
    data() {
        var validatePass = (rule, value, callback) => {
            // console.log(value);
            if (!value) {
                return callback(new Error('号码不能为空'))
            }
            // else if (value.length > 11) {
            //     return callback(new Error('号码超限'))
            // }
            setTimeout(() => {
                let ze = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/
                if (!ze.test(value)) {
                    callback(new Error('请输入合法手机号/电话号'))
                } else {
                    callback()
                }
            }, 1000)

        }
        return {
            ruleForm: {
                accountNumber: '',
                password: '',
                cellPhoneNumber: '',
                phoneCode: ''
            },
            ruleForm2: {
                password: '',
                check_password: ''
            },
            rules: {
                accountNumber: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                cellPhoneNumber: [
                    { validator: validatePass, trigger: 'blur' },
                    // { required: true, message: '手机号码不能为空', trigger: 'blur' },
                    // { pattern:/^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: '请输入合法手机号/电话号', trigger: 'blur' }
                ],
                phoneCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 4, max: 6, message: '长度在 4 到 6 个字符', trigger: 'blur' }
                ]
            },
            rules2: {
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                check_password: [
                    { required: true, message: '请确认密码', trigger: 'blur' },
                ],
            },

            tabSwitching: [
                { id: 0, title: '密码登录', isflag: true },
                { id: 1, title: '短信登录', isflag: false }
            ],
            passwordTitle: 'password',
            isLoginBox: true,//判断密码和短信
            isTime: true,//判断获取手机号验证码倒计时
            AMinute: 60,//验证码倒计时时间
            sessionId: '',
            sig: '',
            token: '',
            firstLogin: false,
            forgetJump: true
        };
    },

    mounted() {
        // console.log(this.dialogVisible);
        
    },
    watch: {
        //监听显示第一次加载
        dialogVisible() {
            if (this.dialogVisible == true) {
                if (!window.ic) {
                    // this.loadAWSC(this.init)
                    this.init()
                } else {
                    window.ic.reset()//切换重置验证初始化状态
                    this.$refs['ruleForm'].resetFields(),
                    this.sessionId = '',
                    this.sig = '',
                    this.token = '',
                    this.firstLogin = false
                }
            }
        },
    },
    methods: {
        //登录
        onSubmit1(formName) {
            // console.log(this.$refs[formName]);
            this.$refs[formName].validate((valid) => {
                // console.log(valid);
                if (valid) {
                    if (this.isLoginBox == true) {//账号登录
                        let params = {
                            phone: this.ruleForm.accountNumber,
                            password: this.ruleForm.password,
                            sc_token: this.token,
                            sc_sessionId: this.sessionId,
                            sc_sig: this.sig
                        }
                        onSubmit(params).then(res => {
                            if (res.code == 200) {
                                // console.log(res.data);
                                // sessionStorage.setItem('token', JSON.stringify(res.data.token))
                                localStorage.setItem('token', JSON.stringify(res.data.token))
                                this.$store.dispatch('getUserList')
                                this.$notify({
                                    title: '成功',
                                    message: '登录成功',
                                    type: 'success',
                                })
                                this.handleClose()
                            } else {
                                ElMessage.error(res.msg)

                            }
                        })
                    } else {//短信登录
                        let params = {
                            phone: this.ruleForm.cellPhoneNumber,
                            sms_code: this.ruleForm.phoneCode,
                        }
                        phoneLoginBox(params).then(res => {
                            if (res.code == 200) {
                                // console.log("短信",res.data);
                                // sessionStorage.setItem('token', JSON.stringify(res.data.token))
                                localStorage.setItem('token', JSON.stringify(res.data.token))
                                // console.log("1不用设置，2是设置",res.data.repair_password);
                                if (res.data.repair_password == 2) {
                                    this.firstLogin = true
                                }else{
                                    this.handleClose()
                                }
                                
                                this.$store.dispatch('getUserList')
                                this.$notify({
                                    title: '成功',
                                    message: '登录成功',
                                    type: 'success',
                                })
                            } else {
                                ElMessage.error(res.msg)

                            }
                        })
                    }
                } else {
                    ElMessage.error('请填写数据，进行提交')
                    return false
                }
            })
            window.ic.reset()//切换重置验证初始化状态
        },
        onSubmit2(ruleForm2) {
            //  console.log(ruleForm2);
            this.$refs[ruleForm2].validate((valid) => {
                if (valid) {
                   
                    let params = {
                        password: this.ruleForm2.password,
                        check_password: this.ruleForm2.check_password
                    }
                    repairPassword(params).then((res) => {
                        if (res.code == 200) {
                            // console.log(res.data.repair_password);
                            this.handleClose()
                            this.firstLogin = false
                            this.ruleForm2.password = ''
                         this.ruleForm2.check_password = ''
                        }
                    }).catch((err) => {
                        //    console.log("我没有请求到接口",err);
                    })

                }
                //   let params = {
                //             phone: this.ruleForm.cellPhoneNumber,
                //             sms_code: this.ruleForm.phoneCode,
                //         }
                //         phoneLoginBox(params).then(res => {
                //             if (res.code == 200) {
                //                 // console.log("短信",res.data);
                //                 // sessionStorage.setItem('token', JSON.stringify(res.data.token))
                //                 localStorage.setItem('token', JSON.stringify(res.data.token))
                //                 // console.log("1不用设置，2是设置",res.data.repair_password);
                            
                //                     this.handleClose()
                //                 this.$store.dispatch('getUserList')
                //                 this.$notify({
                //                     title: '成功',
                //                     message: '登录成功',
                //                     type: 'success',
                //                 })
                //             } else {
                //                 ElMessage.error(res.msg)

                //             }
                //         })
            })



        },
        //获取时间
        getTime() {
            this.isTime = false
            if (this.isTime == false) {
                this.timer = setInterval(() => {
                    this.AMinute--
                    if (this.AMinute === 0) {
                        this.isTime = true
                        this.AMinute = 60
                        clearInterval(this.timer)
                    }
                }, 1000)
            }
        },
        //获取手机号验证码
        getVerificationCode() {
            let ze = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/
            // const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (!ze.test(this.ruleForm.cellPhoneNumber)) {
                ElMessage.error('请输入合法手机号/电话号')
            }
            // else if(!reg.test(this.ruleForm.cellPhoneNumber)){
            //     ElMessage.error('位数超限')
            // } 
            else {
                let param = {
                    phone: this.ruleForm.cellPhoneNumber,
                    sc_token: this.token,
                    sc_sessionId: this.sessionId,
                    sc_sig: this.sig,

                }
                getLoginBoxSms(param).then(res => {
                    // console.log(res);
                    if (res.code == '200') {
                        ElMessage.success({ message: res.msg, type: 'success', })
                        this.getTime()
                    } else {
                        window.ic.reset()//切换重置验证初始化状态
                        ElMessage.error('请先智能通过验证')
                    }
                })
            }
            //     const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/

            // if (!reg.test(this.ruleForm.cellPhoneNumber)) {
            //         ElMessage.error('请输入合法手机号/电话号')
            //     }

        },
        /* global AWSC */
        //获取登录验证  
        async init() {
            var that = this
            // 实例化ic对象
            AWSC.use("ic", function (state, module) {
                // 初始化
                window.ic = module.init({
                    appkey: "FFFF0N00000000008033",
                    scene: "ic_LoginBox",
                    renderTo: 'sc',
                    success: function (data) {
                        that.sessionId = data.sessionId
                        that.sig = data.sig
                        that.token = data.token
                    },
                    // 验证失败时触发该回调参数
                    fail: function (failCode) {
                        // console.log(failCode,'验证失败')
                        window.console && console.log(failCode, '验证失败')
                    },
                    // 验证码加载异常时触发该回调参数
                    error: function (errorCode) {
                        // console.log(errorCode,'加载异常')
                        window.console && console.log(errorCode, '加载异常')
                    }
                });
            })
        },

        //登录切换
        recordSwitch(val) {
            this.isLoginBox = val,
                this.passwordTitle = 'password',
                window.ic.reset()//切换重置验证初始化状态
            this.sessionId = '',
                this.sig = '',
                this.token = ''
        },
        //关闭弹窗
        handleClose() {
            this.LoginBoxClose()
        },
        //是否查看密码
        lookSetting() {
            if (this.passwordTitle == 'password') {
                this.passwordTitle = 'text'
            } else {
                this.passwordTitle = 'password'
            }
        },
        unmounted() {
            this.LoginBoxClose()
        },
        toPassWordEdit() {
            this.$router.push({
                path: '/ForgetPassword',
            })

            this.LoginBoxClose()

        }
    },
};
</script>

<style  scoped>
.LoginBox-popup {
    display: flex;
}

.left-content {
    width: 30%;
    padding: 0 20px;
    border-right: 1px solid #eaeaea;
}

.right-content {
    width: 56%;
    margin: 20px 0 30px 0;
    padding: 0 3%;
}

.log-into-the-site.scan-code-prompt {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
}

.log-onto-code {
    width: 199px;
    height: 199px;
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: 8px 8px 63px rgba(66, 107, 246, 0.15);
    border-right: 2px solid #ECECEC;
    position: relative;
    margin-top: 32px;
}

.code-mask-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
}

.log-onto-code>img {
    width: 100%;
    /* height: 100%; */
}

.log-into-the-site {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
}

.log-into-the-site>span {
    color: #15B628;
}

.download-app {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    text-align: center;
}

.head-record {
    display: flex;
    justify-content: center;
    cursor: pointer;
    height: 18px;
    line-height: 18PX;
}

.head-record>div:first-child {
    border-right: 1px solid #000;
}

.head-record>div {
    padding: 0 22px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
}

.record-active {
    color: #426BF6 !important;
}

.personal-information {
    margin-top: 35px;
    background: rgba(255, 255, 255, 0.35);
    border-radius: 8px;
    border: 3px solid #eaeaea;
    box-shadow: 50px 0 32px rgba(66, 107, 246, 0.35);
}

.personal-information-first {
    margin-top: 35px;
    background: rgba(255, 255, 255, 0.35);
    border-radius: 8px;
    border: 3px solid #eaeaea;
    box-shadow: 50px 0 32px rgba(66, 107, 246, 0.35);
}

.account-number {
    width: 100%;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
}

.account-number /deep/ .el-input__wrapper {
    box-shadow: none;
}

.account-number /deep/ .el-input {
    height: 100%;
    width: 100%;
}

.account-number /deep/ .el-input__inner {
    padding-left: 10px;
}

.password-number /deep/ .el-input__inner {
    padding: 0 100px 0 10px;
}

.mobile-phone {
    position: absolute;
    z-index: 99;
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
}

.mobile-phone-first {
    position: absolute;
    z-index: 99;
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-left: 10px;
}

.inputBox {
    width: 100%;
    padding-left: 100px;
    display: flex;
    align-items: center;
}

.mobile-phone .el-icon {
    margin-right: 10px;
}

.mobile-phone-first .el-icon {
    margin-left: 35px;

}

.password-settings {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100px;
    right: 0;
    align-items: center;
    justify-content: space-around;
}

.password-look {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
}

#sc {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
    height: 100% !important;
}

#sc /deep/ .sm-btn-wrapper {
    width: 100% !important;
    height: 100% !important;
}

#sc /deep/ .sm-btn {
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    line-height: 50px !important;
}

#sc /deep/ #rectMask {
    width: 100% !important;
    height: 100% !important;
}

#sc /deep/ .rect-top,
#sc /deep/ .rect-bottom {
    width: 100% !important;
    height: 25px !important;
}

.forgot-password {
    cursor: pointer;
    color: #426BF6 !important;
    margin-left: -10px;
}

.button-box {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.register-btn {
    width: 45%;
    height: 41px;
    line-height: 41px;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
}

.record-btn {
    width: 45%;
    height: 41px;
    line-height: 41px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    background: #426BF6;
    border-radius: 8px;
    cursor: pointer;
}

.cell-phone {
    padding-right: 100px;
}

.get-verification-code {
    width: 100px;
    position: absolute;
    right: 0;
    text-align: center;
    border-left: 1px solid #eaeaea;
    color: #426BF6;
    opacity: 0.7;
    cursor: pointer;
}

.el-form-item {
    margin-bottom: 0 !important;
}

.el-form-item /deep/ .el-form-item__error {
    z-index: 99 !important;
    margin: 2px 0 0 390px;
}</style>