import axios from "@/util/request.js"

//账号登录接口
export function onSubmit(params) {
    return axios.service({
        url: 'users/login',
        method: 'post',
        params: params
    });
}
//获取短信验证码
export function getLoginBoxSms(params) {
    return axios.service({
        url: 'users/getLoginSms',
        method: 'post',
        params: params
    });
}
//密码补充接口
export function repairPassword(params) {
    return axios.service({
        url: '/users/repairPassword',
        method: 'post',
        params: params
    });
}
//修改密码接口
export function editPwd(params) {
    return axios.service({
        url: '/users/editPwd',
        method: 'post',
        params: params
    });
}
//短信验证码登录
export function phoneLoginBox(params) {
    return axios.service({
        url: '/users/phoneLogin',
        method: 'post',
        params: params
    });
}
//忘记密码 手机号验证 更改密码
export function passWordEdit(params) {
    return axios.service({
        url: 'users/passWordEdit',
        method: 'post',
        params: params
    });
}