<template>
  <Header :domTitle="domTitle"></Header>
  <LoginBox ref="LoginBox" :dialogVisible="dialogVisible" :LoginBoxClose="LoginBoxClose"></LoginBox>
  <router-view></router-view>
  <Foot></Foot>
</template>


<script>
import Header from "../src/components/header/header.vue"
import LoginBox from '@/components/LoginBox/LoginBox'
import Foot from "../src/components/foot/foot.vue"
export default {
  components: {
    Header,
    LoginBox,
    Foot
  },
  name: 'App',
  data() {
    return {
      domTitle:"",
      dialogVisible:false,
    }
  },
  watch: {
    '$route'(to, from) { //监听路由是否变化
      var domTitle = to.meta.title
      if (domTitle) {
        this.domTitle = to.meta.title
        document.title = domTitle
      }
    },
  },
  methods: {
    LoginBoxClose() {
      // console.log("前",this.dialogVisible);
      this.dialogVisible = !this.dialogVisible
      // console.log("后",this.dialogVisible);
    },
  },  
}
</script>

<style>
* {
  margin: 0;
}

a {
  text-decoration: none;
}

ul {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none;
}
</style>
