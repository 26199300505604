// //(http转https 这是线下的,往上提的时候记得打开)
// if (location.protocol !== 'https:') {
//   var newUrl = location.href.replace(/^http:/, 'https:');
//   console.log(newUrl);
//   window.location.href = newUrl;    
// }
const PcUrls = {
    // 免费试听
  'https://www.zhiwuyakaoyan.com/freeclass':'https://m.zhiwuyakaoyan.com/pages/freeclass/freeclass',
    //选课中心
  'https://www.zhiwuyakaoyan.com/selectcourse': 'https://m.zhiwuyakaoyan.com/pages/selectcourse/selectcourse',
    //学习中心
  'https://www.zhiwuyakaoyan.com/mybought':'https://m.zhiwuyakaoyan.com/pages/mybought/mybought',
    //个人中心
  'https://www.zhiwuyakaoyan.com/usercenter':'https://m.zhiwuyakaoyan.com/pages/usercenter/usercenter',
};
let Url=window.location.href
let hasJumped = false;
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  // location.href = 'https://m.zhiwuyakaoyan.com/';
   // 在移动设备中访问
   for (let item in PcUrls){
     if(item==Url){
        window.location.href=PcUrls[item]
        hasJumped = true;
        break;
     }
   }
   if (!hasJumped) {
    var mobilehref = 'https://m.zhiwuyakaoyan.com/'
    // 执行默认跳转操作    
    var pathname = window.location.pathname
    if (pathname.indexOf('secret') == -1) {
      window.location.href = mobilehref
    }    
  }
} 

import { createApp } from "vue";
import router from "./router/index"; //引入
import App from "./App.vue";
import "../public/public.css";
import VueLazyload from "vue-lazyload";
// 导入 element-plus
import ElementPlus from "element-plus";
// 导入 element-plus 样式
import "element-plus/dist/index.css";
// 导入 element-plus 图标
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
// 导入 vuex 管理
import store from "./store/index";
//导入 vuex
import vuex from "vuex";
//引入 http跳转https (pc+移动)
import {https} from "./api/https";


const app = createApp(App);

app.use(vuex);
app.use(ElementPlus);
app.use(router);
const loadimage = require("../src/assets/loading.gif");
app.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadimage, // error: errorimage,
});
app.use(store);
https()
// 注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.mount("#app");
